<template>
  <div>
    <PixFilters :getTransections="getTransections" />
    <!-- <v-row class="px-8 pb-5 justify-end">
      <v-btn
        @click="toogleCreditCardLogDilog"
        class="mx-0 py-3"
        small
        color="deep-orange darken-3"
        dark
      >
        <v-icon small left> integration_instructions </v-icon>Logs
      </v-btn>
    </v-row> -->
    <v-data-table
      @click:row="tooglePixDetails"
      class="newPagination clickedRow"
      :page="1"
      :itemsPerPage="perPage"
      :headers="headers"
      :items="transections"
      item-key="id"
    >
      <template v-slot:item.status="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="formatStatus(item.status)[1] != 'subtitles_off'"
              style="cursor: pointer"
              :color="formatStatus(item.status)[2]"
              dark
              v-bind="attrs"
              v-on="on"
            >
              {{ formatStatus(item.status)[1] }}
            </v-icon>
          </template>
          <span>{{ formatStatus(item.status)[0] }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.transaction_code="{ item }">
        <div class="">
          <small class="mr-2">Referência:</small>
          <span class="font-weight-medium"
            >{{ item.reference_code ? item.reference_code : "-" }}
          </span>
        </div>
        <div class="">
          <small class="mr-2">Transaction:</small>
          <span class="font-weight-medium"
            >{{ item.transaction_code ? item.transaction_code : "-" }}
          </span>
        </div>
      </template>
      <template v-slot:item.transaction_date="{ item }">
        <div class="">
          <b>
            <span v-if="item.data_pix.name">{{ item.data_pix.name }}</span>
          </b>
        </div>
        <div class="">
          <span v-if="item.transaction_date">{{
            item.transaction_date.split(" ")[0] | formatDate
          }}</span>
        </div>
      </template>

      <template v-slot:item.link="{ item }">
        <a
          @click.stop.prevent="goToPayment(item.link)"
          v-if="item.link"
          :href="item.link"
          target="_blank"
          >Abrir pagamento</a
        >
        <a style="cursor: auto" v-else>-</a>
      </template>

      <template v-slot:item.value="{ item }">
        <div v-if="item.value" style="min-width: 100px">
          R$ {{ item.value | formatPrice }}
        </div>
      </template>
      <template v-slot:footer.page-text>
        <v-row class="d-flex justify-end">
          <v-col class="pb-0" cols="5">
            <v-container class="d-flex flex-row justify-end max-width pr-0">
              <v-pagination
                v-model="actualPage"
                :length="totalPages"
                :total-visible="8"
              ></v-pagination>
            </v-container>
          </v-col>
          <v-col style="max-width: 130px" class="pt-9 px-0 pb-0" cols="2"
            ><span class="caption">Itens por página</span></v-col
          >
          <v-col style="max-width: 90px" class="pb-0" cols="2">
            <v-select v-model="perPage" :items="[5, 10, 50, 100]"></v-select>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.actions="{ item, isExpanded }">
        <v-tooltip top v-if="item.callback_history.length ? true : false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="btMicro px-1 mr-1 ml-3"
              @click.stop.prevent="
                showCallbackHistory(
                  item.callback_history,
                  item.transaction_code
                )
              "
              x-small
              color="indigo darken-1"
              dark
              ><v-icon center x-small> history </v-icon></v-btn
            >
          </template>
          <span class="miniTtip">Histórico de callbacks</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <CallbackHistoryList
      :transactionCode="transactionCode"
      :toogleCallbackHistoryList="toogleCallbackHistoryList"
      :callbacksList="callbacksList"
      :callbackHistoryDialog="callbackHistoryDialog"
      :creditCard="false"
    />
    <PixDetailsDialog
      :dataPix="dataPix"
      :pixDetailsDialog="pixDetailsDialog"
      :tooglePixDetails="tooglePixDetails"
    />
  </div>
</template>

<script>
import PixDetailsDialog from "./PixDetailsDialog.vue";
import PixFilters from "./PixFilters.vue";
// newManagerBillets/CallbackHistoryList.vue
import CallbackHistoryList from "../../../../../components/clients/billets/newManagerBillets/CallbackHistoryList.vue";
export default {
  watch: {
    actualPage(v, oldV) {
      //console.log("qsqsqs", oldV);
      if (!oldV || !v) {
        return;
      }
      this.getTransections(this.$store.state.lastCreditCardsParans, v, v);
    },
    perPage(v) {
      // console.log('perpag', v)
      // this.$store.state.lastBilletsParans,
      this.getTransections(this.$store.state.lastCreditCardsParans, 1, v);
    },
    operator(v, oldV) {
      if (v != oldV) {
        this.getTransections(this.$store.state.lastCreditCardsParans, 1, v);
      }
    },
  },
  methods: {
    tooglePixDetails(data) {
      this.dataPix = { ...data };
      // this.dataPix.status = data.status;
      // this.dataPix.transaction_date = data.transaction_date;
      this.pixDetailsDialog = !this.pixDetailsDialog;
    },
    goToPayment(link) {
      window.open(link, "_blank");
    },
    toogleCallbackHistoryList() {
      // this.callbacksList = []
      this.callbackHistoryDialog = !this.callbackHistoryDialog;
    },
    showCallbackHistory(data, code) {
      this.callbacksList = [];
      setTimeout(() => {
        this.callbacksList = [...data];
      }, 10);
      this.transactionCode = code;
      this.toogleCallbackHistoryList();
    },
    toggleDataTransectionDialog(data) {
      // console.log('aassdas', data)
      this.dataTransectionDialog = !this.dataTransectionDialog;
      if (this.dataTransectionDialog) {
        this.dataRecurrence = {};
        this.dataTransection = { ...data };
      }
    },
    toogleCreditCardLogDilog() {
      this.creditCardLogDilog = !this.creditCardLogDilog;
    },
    toogleCallbackHistoryList() {
      // this.callbacksList = []
      this.callbackHistoryDialog = !this.callbackHistoryDialog;
    },
    showCallbackHistory(data, code) {
      this.callbacksList = [];
      setTimeout(() => {
        this.callbacksList = [...data];
      }, 10);
      this.transactionCode = code;
      this.toogleCallbackHistoryList();
    },
    formatStatus(status) {
      switch (status) {
        case "Andamento":
          return ["Andamento", "highlight_off", "red"];
        case "Devolvido":
          return ["Devolvido", "assignment_return", "red lighten-3"];
        case "Aberto":
          return ["Aberto", "verified_user", "primary"];
        case "Liquidado":
          return ["Liquidado", "thumb_up_alt", "success"];
        case "Expirado":
          return ["Expirado", "announcement", "orange darken-3"];
        case "Falha no Registro":
          return ["Falha no Registro", "highlight_remove", "red accent-4"];
        case "Devolucao em processamento":
          return [
            "Devolucao em processamento",
            "hourglass_bottom",
            "red lighten-1",
          ];
        default:
          return ["Baixado/Cancelado", "block", "grey darken-1"];
      }
    },
    resetPagination() {
      this.transections = [];
      this.actualPage = 1;
      this.totalPages = 0;
    },
    getTransections(data, page) {
      let complementPag = page ? `/?page=${page}` : "";
      this.actualPage = page;
      let payload = { ...data };
      var obj = {
        url: "/api/v1/pix/get-all" + complementPag,
        query: payload,
        method: "post",
      };
      obj.query.per_page = this.perPage;
      //   this.$store.commit("updateLastCreditCardsParans", obj.query);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        console.log("datdatda");
        if (Array.isArray(response.data)) {
          this.resetPagination();
          return;
        }
        this.transections = [...response.data.data];
        this.totalPages = response.data.last_page;
        this.actualPage = response.data.current_page;
      });
    },
    handleExpansion(item, state) {
      // console.log(item, state);
      this.expanded = [];
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
  },
  data() {
    return {
      dataPix: {},
      pixDetailsDialog: false,
      setedPage: false,
      totalPages: 0,
      perPage: 5,
      actualPage: 0,
      dataTransection: null,
      dataTransectionDialog: false,
      dataRecurrence: {},
      dataRecurrenceDialog: false,
      creditCardLogDilog: false,
      callbackHistoryDialog: false,
      callbacksList: [],
      transactionCode: "",
      expanded: [],
      expanded: [],
      transections: [],
      headers: [
        {
          text: "Status",
          value: "status",
          align: "center",
          sortable: false,
        },
        {
          text: "Cod. referência/Transaction_code",
          value: "transaction_code",
          align: "left",
          sortable: false,
        },
        {
          text: "Devedor/Data",
          value: "transaction_date",
          align: "left",
          sortable: false,
        },
        {
          text: "Link do pgto.",
          value: "link",
          align: "center",
          sortable: false,
        },
        {
          text: "Valor",
          value: "value",
          align: "left",
          sortable: false,
        },
        {
          text: "Ações",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      this.getTransections();
    }, 500);
  },
  props: {
    operator: String,
  },
  components: {
    PixFilters,
    CallbackHistoryList,
    PixDetailsDialog,
  },
};
</script>

<style>
.v-data-table__expanded__content {
  box-shadow: inset 1px -3px 4px -2px rgba(102, 102, 102, 1) !important;
  background: #f2fbff !important;
}

.v-btn.v-btn--disabled.btAverageSize.disableBt.recurrence {
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
  opacity: 0.4 !important;
}

.v-btn.v-btn--disabled.btAverageSize.disableBt.callHistory {
  background-color: #3949ab !important;
  border-color: #3949ab !important;
  opacity: 0.7 !important;
}
.clickedRow tr {
  cursor: pointer;
}
</style>