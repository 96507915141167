<template>
  <v-data-table
    :items-per-page="5"
    :headers="headers"
    :items="callbacksList"
    class="elevation-0 scrollTable"
  >
    <template v-slot:item.sent="{ item }">
      <v-tooltip top color="">
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            :class="[
              setColorLine(item.sent, item.send_error)[1],
              'pa-1 rounded',
            ]"
          >
            <v-icon class="white--text font-weight-medium">{{
              item.sent ? "call_made" : "call_received"
            }}</v-icon>
          </div>
        </template>
        <span class="">{{ item.sent ? "Envio" : "Resposta" }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.send_date="{ item }">
      <div :class="setColorLine(item.sent, item.send_error)[2]">
        {{
          (item.send_date
            ? item.send_date.split(" ")[0]
            : item.receipt_date.split(" ")[0]) | formatDate
        }}<span
          ><v-icon class="ml-3 mb-1 font-weight-black" x-small>schedule</v-icon>
          {{
            item.send_date
              ? item.send_date.split(" ")[1]
              : item.receipt_date.split(" ")[1]
          }}</span
        >
      </div>
    </template>
    <template v-slot:item.message="{ item }">
      <div :class="setColorLine(item.sent, item.send_error)[2]">
        {{ item.message }}
      </div>
    </template>
    <template v-slot:item.status="{ item }">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            @click="
              setStatusBillet(item.status)[2].indexOf('Falha') < 0
                ? null
                : confirmRetryRegisterBillet(item, $event)
            "
            :color="setStatusBillet(item.status)[0]"
            :class="setStatusBillet(item.status)[3]"
            center
            v-bind="attrs"
            v-on="on"
          >
            {{ setStatusBillet(item.status)[1] }}
          </v-icon>
        </template>
        <span class="miniTtip" v-html="setStatusBillet(item.status)[2]"></span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
export default {
  methods: {
    setColorLine(sent, err) {
      if (err) {
        return ["close", "red lighten-2", "red--text text--lighten-2"];
      }
      if (sent) {
        return ["call_made", "indigo lighten-2", "indigo--text"];
      } else {
        return ["call_received", "teal lighten-2", "teal--text"];
      }
    },
    setStatusBillet(data) {
      //console.log("fff");
      let status = {
        "Baixado/Cancelado": [
          "grey darken-1",
          "block",
          "Cancelado",
          "justIcon",
        ],
        "Baixando/Cancelando": [
          "grey darken-1",
          "warning",
          "Aguardando cancelamento",
          "justIcon",
        ],
        Andamento: [
          "orange darken-2",
          "query_builder",
          "Aguardando registro",
          "justIcon",
        ],
        Aberto: ["blue darken-1", "verified", "Registrado", "justIcon"],
        Liquidado: ["green darken-3", "attach_money", "Liquidado", "justIcon"],
        "Falha no Registro": [
          "red darken-4",
          "error",
          "Falha ao tentar registrar",
          "iconButton",
        ],
        "Falha na Baixa/Cancelamento": [
          "red darken-4",
          "error",
          "Falha no cancelamento",
          "iconButton",
        ],
        Vencido: ["red lighten-1", "event_busy", "Vencido", "justIcon"],
        Expirado: ["red lighten-1", "event_busy", "Pgto. expirado", "justIcon"],
        Devolvido: [
          "grey lighten-1",
          "backspace",
          "Pgto. devolvido",
          "justIcon",
        ],
        "Devolucao em processamento": [
          "red lighten-1",
          "hourglass_bottom",
          "Devolucao em processamento",
          "justIcon",
        ],
      };
      return status[data];
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Ação",
          value: "sent",
          sortable: false,
          align: "center",
          width: 55,
        },
        // {
        //     text: "Data de criação",
        //     value: "created_at",
        //     sortable: true,
        // },
        {
          text: "Data do evento",
          value: "send_date",
          sortable: false,
          // align: "center",
          width: 200,
        },

        {
          text: "Mensagem",
          value: "message",
          sortable: false,
          // width: 450,
        },
        {
          text: "Status do boleto",
          value: "status",
          sortable: false,
          align: "center",
          width: 140,
          // class: "minWidthCell"
        },
      ],
    };
  },
  props: {
    callbacksList: Array,
  },
};
</script>

<style>
.minWidthCell {
  min-width: 300px;
}

.sendCallback {
  color: #5a77b3;
}
</style>