<template>
  <div style="max-width: 1600px; margin: auto; position: relative">
    <v-row class="mt-1 mb-2 d-flex align-start justify-space-between">
      <v-col cols="12">
        <h4 class="titlePages text-left font-weight-medium mb-4">Transações</h4>
        <p
          style="max-width: 50%"
          class="mb-0 text-body-2 font-weight-light text-left mt-2"
        >
          Confira a suas transações via cartão de crédito utilizando os filtros
          abaixo e também selecionando a operadora.
        </p>
      </v-col>
    </v-row>
    <v-card class="mt-5">
      <v-row justify="center">
        <v-col cols="12" md="8" class="">
          <v-card-title>Lista de transações PIX</v-card-title>
          <v-card-subtitle class="text-left">
            Faça uma pesquisa mais detalhada usando os filtros abaixo.
          </v-card-subtitle>
        </v-col>
        <v-col cols="12" md="4" class="px-8 pt-9 pb-0 d-flex"> </v-col>
      </v-row>
      <v-divider style="border: solid 2px" class="teal lighten-1"></v-divider>
      <v-card-text class="">
        <PixList />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import PixList from "./PixList.vue";
export default {
  watch: {
    configuredOperators(v) {
      if (v.length) {
        this.operator = this.configuredOperators[0].name;
      }
    },
    creditCardDialog(v) {
      console.log("setoperadora");
      if (v) {
        this.operator = this.configuredOperators[0].name;
      }
    },
  },
  methods: {},
  mounted() {},
  data() {
    return {
      configuredOperators: [],
      operator: "",
      operatorSelected: "",
    };
  },
  props: {
    // configuredOperators: Array,
    // creditCardDialog: Boolean
  },
  components: { PixList },
};
</script>

<style lang="scss" scoped></style>