<template>
  <v-dialog persistent v-model="pixDetailsDialog" fullscreen>
    <v-card>
      <v-card-title style="border-bottom: 1px solid #ccc" class="px-2 mb-5"
        ><v-app-bar height="50" flat color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h5 pl-0 pt-0">
            Detalhes da transação
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="tooglePixDetails"
            class="mt-6"
            color="red"
            fab
            icon
            dark
            x-small
            absolute
            top
            right
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar></v-card-title
      >
      <v-card-text
        id="printable"
        v-if="dataPix.user"
        class="pt-10 mx-auto"
        style="max-width: 1080px"
      >
        <v-row justify="space-between" class="px-6 grey lighten-4 mb-5">
          <!-- <v-spacer></v-spacer> -->
          <v-col cols="12" md="9" class="mt-1">
            <h3
              :class="[
                dataPix.status == 'Liquidado'
                  ? 'teal--text text--darken-1'
                  : 'red--text text--lighten-1',
                'font-weight-medium',
              ]"
            >
              <span class="font-weight-light">Status:</span>
              {{ dataPix.status }}
            </h3>
          </v-col>
          <!-- <v-col cols="12" md="4" class="mt-1"></v-col> -->
          <v-col cols="12" md="3" class="mt-1">
            <h3
              :class="[
                dataPix.status == 'Liquidado'
                  ? 'teal--text text--darken-1'
                  : 'red--text text--lighten-1',
                'font-weight-medium',
              ]"
            >
              <span class="font-weight-light">Transação:</span>
              {{ dataPix.status == "Liquidado" ? "Paga" : "Não paga" }}
            </h3>
          </v-col>
        </v-row>
        <!-- <v-divider v-if="dataPix.fail" class="mt-4 mb-3"></v-divider> -->
        <v-row v-if="dataPix.fail" justify="start" class="px-6">
          <v-col cols="12" md="4" class="mt-1">
            <h4>
              <small color="text--black">Código do erro</small>
            </h4>
            <h3 class="font-weight-regular red--text text--lighten-1">
              {{ dataPix.fail.error.code }}
            </h3></v-col
          >
          <v-col cols="12" md="8" class="mt-1">
            <h4>
              <small color="text--black">Mensagem do erro</small>
            </h4>
            <h3 class="font-weight-regular red--text text--lighten-1">
              {{ dataPix.fail.error.message }}
            </h3></v-col
          >
        </v-row>
        <v-divider v-if="dataPix.fail" class="mt-4"></v-divider>
        <v-divider v-if="dataPix.fail" class=""></v-divider>
        <v-divider v-if="dataPix.fail" class="mb-4"></v-divider>
        <v-row justify="start" class="px-6">
          <v-col cols="12" md="4" class="mt-1">
            <h4>
              <small color="text--black">Data da transação</small>
            </h4>
            <h3 class="font-weight-regular">
              {{ dataPix.transaction_date | formatDate }}
            </h3></v-col
          >
          <v-col cols="12" md="4" class="mt-1">
            <h4>
              <small color="text--black">Expiração da transação</small>
            </h4>
            <h3 class="font-weight-regular">
              {{ dataPix.expiration_date.split(" ")[0] | formatDate }} -
              {{ dataPix.expiration_date.split(" ")[1] }}
            </h3></v-col
          >
          <v-col cols="12" md="4" class="mt-1">
            <h4>
              <small color="text--black">Valor</small>
            </h4>
            <h3 class="font-weight-regular">
              R$ {{ dataPix.data_pix.value | formatPrice }}
            </h3></v-col
          >
        </v-row>
        <v-divider class="mt-4 mb-3"></v-divider>
        <v-row justify="start" class="px-6">
          <v-col cols="12" md="4" class="mt-1">
            <h4>
              <small color="text--black">Tarifa do bancária</small>
            </h4>
            <h3 v-if="dataPix.billing_bank" class="font-weight-regular">
              R$ {{ dataPix.billing_bank | formatPrice }}
            </h3>
            <h3 v-else class="font-weight-medium">-</h3>
          </v-col>
          <v-col cols="12" md="4" class="mt-1">
            <h4>
              <small color="text--black">Tarifa Community Ware</small>
            </h4>
            <h3 v-if="dataPix.billing_value" class="font-weight-regular">
              R$ {{ dataPix.billing_value | formatPrice }}
            </h3>
            <h3 v-else class="font-weight-medium">-</h3>
          </v-col>
        </v-row>
        <v-divider v-if="!dataPix.fail" class="mt-4"></v-divider>
        <v-divider v-if="!dataPix.fail" class=""></v-divider>
        <v-divider v-if="!dataPix.fail" class="mb-4"></v-divider>
        <v-divider v-if="dataPix.fail" class="mt-4 mb-3"></v-divider>

        <v-row justify="start" class="px-6">
          <v-col cols="12" md="4" class="mt-1">
            <h4>
              <small color="text--black">Cliente</small>
            </h4>
            <h3 class="font-weight-regular">{{ dataPix.user.name }}</h3></v-col
          >
          <v-col cols="12" md="4" class="mt-1">
            <h4>
              <small color="text--black">Documento</small>
            </h4>
            <h3 class="font-weight-regular" v-if="dataPix.user.document">
              {{ dataPix.user.document | formatCnpjCpf }}
            </h3></v-col
          >
          <v-col cols="12" md="4" class="">
            <h4>
              <small color="text--black">E-mail</small>
            </h4>
            <h3 class="font-weight-regular">
              {{ dataPix.user.email }}
            </h3></v-col
          >
        </v-row>
        <v-divider class="mt-4 mb-3"></v-divider>
        <v-row justify="start" class="px-6">
          <v-col cols="12" md="4" class="mt-1">
            <h4>
              <small color="text--black">Devedor</small>
            </h4>
            <h3 class="font-weight-regular">
              {{ dataPix.data_pix.name }}
            </h3></v-col
          >
          <v-col cols="12" md="4" class="mt-1">
            <h4>
              <small color="text--black">Documento</small>
            </h4>
            <h3 class="font-weight-regular" v-if="dataPix.data_pix.document">
              {{ dataPix.data_pix.document | formatCnpjCpf }}
            </h3></v-col
          >
          <v-col cols="12" md="4" class="">
            <h4>
              <small color="text--black">E-mail</small>
            </h4>
            <h3 class="font-weight-regular" v-if="dataPix.data_pix.document">
              {{ dataPix.data_pix.email }}
            </h3></v-col
          >
        </v-row>
        <v-divider class="mt-4 mb-3"></v-divider>

        <v-row justify="start" class="px-6">
          <v-col cols="12" md="4" class="mt-1">
            <h4>
              <small color="text--black">Data do pagamento</small>
            </h4>
            <h3 v-if="dataPix.pix_liquided" class="font-weight-medium">
              {{ dataPix.pix_liquided.payment_date.split(" ")[0] | formatDate }}
              -
              {{ dataPix.pix_liquided.payment_date.split(" ")[1] }}
            </h3>
            <h3 v-else class="font-weight-medium">-</h3>
          </v-col>
          <v-col cols="12" md="4" class="mt-1">
            <h4>
              <small color="text--black">Nome do pagador</small>
            </h4>
            <h3 class="font-weight-regular" v-if="dataPix.pix_liquided">
              {{ dataPix.pix_liquided.name }}
            </h3>
            <h3 v-else class="font-weight-medium">-</h3>
          </v-col>
          <v-col cols="12" md="4" class="mt-1">
            <h4>
              <small color="text--black">Documento</small>
            </h4>
            <h3 class="font-weight-regular" v-if="dataPix.pix_liquided">
              {{ dataPix.pix_liquided.document | formatCnpjCpf }}
            </h3>
            <h3 v-else class="font-weight-medium">-</h3>
          </v-col>
        </v-row>
        <v-divider v-if="dataPix.pix_returned" class="mt-4 mb-3"></v-divider>
        <v-row v-if="dataPix.pix_returned" justify="start" class="px-6">
          <v-col cols="12" md="4" class="mt-1">
            <h4>
              <small color="text--black">Data da devolução</small>
            </h4>
            <h3 class="font-weight-regular">
              {{
                dataPix.pix_returned.returned_date.split(" ")[0] | formatDate
              }}
              -
              {{ dataPix.pix_returned.returned_date.split(" ")[1] }}
            </h3>
          </v-col>
          <v-col cols="12" md="8" class="mt-1">
            <h4>
              <small color="text--black">Motivo da devolução</small>
            </h4>
            <h3 class="font-weight-regular">
              {{ dataPix.pix_returned.reason }}
            </h3>
          </v-col>
        </v-row>
        <v-divider class="mt-4"></v-divider>
        <v-divider class=""></v-divider>
        <v-divider class="mb-4"></v-divider>
        <v-row justify="start" class="px-6">
          <v-col cols="12" md="6" class="mt-1">
            <h4>
              <small color="text--black">Código de referência</small>
            </h4>
            <h3 class="font-weight-regular">
              {{ dataPix.reference_code }}
            </h3></v-col
          >
          <v-col cols="12" md="6" class="mt-1">
            <h4>
              <small color="text--black">Transaction code</small>
            </h4>
            <h3 class="font-weight-regular">
              {{ dataPix.transaction_code }}
            </h3></v-col
          >
        </v-row>
        <v-divider class="mt-4 mb-3"></v-divider>
        <v-row justify="start" class="px-6">
          <v-col cols="12" md="6" class="mt-1">
            <h4>
              <small color="text--black">URL de callback</small>
            </h4>
            <h3 class="font-weight-regular">
              {{ dataPix.callback_url ? dataPix.callback_url : "-" }}
            </h3></v-col
          >
          <v-col cols="12" md="6" class="mt-1">
            <h4>
              <small color="text--black">PIX id (txid)</small>
            </h4>
            <h3 class="font-weight-regular">
              {{ dataPix.txid ? dataPix.txid : "-" }}
            </h3></v-col
          >
        </v-row>
        <v-divider class="my-5"></v-divider>
        <v-row class="px-10 mt-10 d-flex">
          <h3 class="font-weight-light mb-3">Lista de callbacks</h3>
          <CallbackDetails
            style="width: 100%"
            :callbacksList="dataPix.callback_history"
          />
        </v-row>
      </v-card-text>
      <!-- <v-card-actions
        class="px-0 mx-3"
        style="border-top: 1px solid rgb(220, 220, 220)"
      >
        <v-spacer class="py-5"></v-spacer>
        <v-btn color="red" text @click="tooglePixDetails"> Fechar </v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
import CallbackDetails from "./CallbackDetails.vue";
export default {
  methods: {
    viewPrint() {
      window.print();
      // let printableData = document.getElementById("printable").innerHTML;
      // let printArea = window.open("about:blank");

      // printArea.document.write(printableData);
      // printArea.window.print();
      // printArea.window.close();
    },
  },
  data() {
    return {};
  },
  props: {
    dataPix: Object,
    pixDetailsDialog: Boolean,
    tooglePixDetails: Function,
  },
  components: { CallbackDetails },
};
</script>

<style>
@media print {
  body * {
    visibility: hidden;
  }
  #printable,
  #printable * {
    visibility: visible;
  }
  #printable {
    position: fixed;
    left: 0;
    top: 0;
  }
}
</style>